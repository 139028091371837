import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import {
  BrowserRouter,
  Route,
  Redirect,
  Switch,
} from 'react-router-dom'
// import Navbar from 'components/Shared/Navbar'
// import Footer from 'components/Shared/Footer'

import LandingPageV4 from 'components/LandingPageV4'
// import Terms from 'components/TermsConditions/Terms'
// import Privacy from 'components/TermsConditions/Privacy'
// import Platform from 'components/Platform'
// import API from 'components/API'
// import Learn from 'components/Learn'
// import Unsubscribe from 'components/Unsubscribe'

import { pageview } from 'helpers/gtag'

const App = () => {
  const history = useHistory()

  useEffect(() => {
    pageview(window.location.pathname + window.location.search)
  }, [history])

  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={LandingPageV4} />
        {/*<Route exact path="/terms">
          <Navbar />
          <Terms />
          <Footer />
        </Route>
        <Route exact path="/privacy">
          <Navbar />
          <Privacy />
          <Footer />
        </Route>
        <Route exact path="/platform">
          <Navbar />
          <Platform />
          <Footer />
        </Route>
        <Route exact path="/api">
          <Navbar />
          <API />
          <Footer />
        </Route>
        <Route exact path="/learn">
          <Navbar />
          <Learn />
          <Footer />
        </Route>
        <Route exact path="/unsubscribe">
          <Navbar />
          <Unsubscribe />
          <Footer />
  </Route>*/}
        <Redirect exact from="" to="/" />
      </Switch>
    </BrowserRouter>
  )
}

export default App
