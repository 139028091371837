import React from 'react'
import { Link } from 'react-router-dom'
import brain from "../../assets/img/image.webp"


const LandingPageV3 = () => (
  <div className="landing-v3-wrapper position-relative overflow-hidden px-4">
    <section className="home-section d-flex flex-column">
      <header className="top-0 position-fixed">
        <div className="content-restriction">
          <div className="d-flex justify-content-between align-items-center">
            <Link to={'/'} className="logo-v3">DIVIAN</Link>
          </div>
        </div>
      </header>
      <div className="header-body flex-grow-1 pt-5 content-restriction w-100 d-flex align-items-center">

        <div className="content-wrapper pl-0 pl-md-5">
          <div className="pb-1">
            <span className="sub-header">Our new web-site is</span>
            <h1 className="ml-n1">Coming soon</h1>
          </div>

          <div className="left-side">
            <span className="sub-header">
              Divian delivers precise biological solutions to agriculture.
              Our AI-driven systems model microbial behavior
              to provide biological products and application schemes tailored to specific crops,
              soil conditions, field types, and pathogens.
            </span>
          </div>
        </div>

        <div className="brain-wrapper d-none d-md-block">
          <img src={brain} alt="" />
        </div>

      </div>
    </section>
  </div>
)

export default LandingPageV3
