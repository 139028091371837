export const GA_TRACKING_ID = 'G-XHQSBCT26X'

export const pageview = (page_path) => {
  window.gtag('config', GA_TRACKING_ID, { page_path })
}

export const event = ({
  action,
  category,
  label,
  value,
}) => {
  window.gtag('event', action, {
    event_category: category,
    event_label: label,
    value,
  })
}
